// Dependencies
import axios from 'axios';

// Variables
// const baseURL = 'http://localhost:4001/api';
const baseURL = 'https://api.upreporting.garritz.com/api';

// Get users initial data
export const getInitialData = async (scb, ecb) => {

	// Request
	axios({
		method: 'get',
		url: baseURL + '/db/users',
		headers: {
			accept: 'application/json',
			// authorization: 'Bearer ' + localStorage.getItem('tcc_fti_jwt')
		}
	}).then((response) => {
		
		// Success callback
		scb(response.data);

	}).catch((error) => {

		// Log error
		console.log(error);

	});

}
