import React from 'react';
import './App.css';
import {
	BrowserRouter,
	Route,
	Routes,
} from 'react-router-dom';

// Sections
import Header from './components/other/Header';

// Screens
import HomePage from './screens/HomePage';

const App = () => {
	return (
		<BrowserRouter>
			{/* Header */}
			<Header>
				<Routes>
					<Route exact path="/" element={<HomePage />} />
				</Routes>
			</Header>
		</BrowserRouter>
	);
}

export default App;
