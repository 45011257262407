import React, { useEffect, useState } from 'react';
import {
	Chart as ChartJS,
	ArcElement,
	Legend,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
} from 'chart.js';
import { Doughnut, Bar, Pie } from 'react-chartjs-2';
import { getInitialData } from '../services/api.service';
import Preloader1 from '../assets/preloader-1.svg';

// Register charting data
ChartJS.register(
	ArcElement,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const HomePage = () => {

	// State
	const [isLoading, toggleLoading] = useState(true);

	// Site state
	const [siteData, setSiteData] = useState({});
	const siteOptions = {
		color: '#FFFFFF',
		responsive: true,
		plugins: {
			title: {
				display: true,
				text: 'Users by Website',
				color: '#FFFFFF'
			},
		},
	};

	// Site state
	const [genderData, setGenderData] = useState({});
	const genderOptions = {
		color: '#FFFFFF',
		responsive: true,
		plugins: {
			title: {
				display: true,
				text: 'Users by Gender',
				color: '#FFFFFF'
			},
		},
	};

	// Age state
	const [ageData, setAgeData] = useState({});
	const ageOptions = {
		color: '#FFFFFF',
		indexAxis: 'y',
		elements: {
			bar: {
				borderWidth: 2,
			},
		},
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: true,
				text: 'Users by Age',
				color: '#FFFFFF'
			},
		},
		categoryPercentage: 1.0,
		barPercentage: 0.4,
		scales: {
			y: {
				ticks: { color: '#FFFFFF' },
				grid: { display: false }
			},
			x: {
				ticks: { color: '#FFFFFF' },
				grid: { color: 'rgba(255, 255, 255, 0.1)' }
			},
			
		}
	};

	// Age state
	const [countryData, setCountryData] = useState({});
	const countryOptions = {
		color: '#FFFFFF',
		indexAxis: 'y',
		elements: {
			bar: {
				borderWidth: 2,
			},
		},
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: true,
				text: 'Users by Country',
				color: '#FFFFFF'
			},
		},
		categoryPercentage: 1.0,
		barPercentage: 0.4,
		scales: {
			y: {
				ticks: { color: '#FFFFFF' },
				grid: { display: false }
			},
			x: {
				ticks: { color: '#FFFFFF' },
				grid: { color: 'rgba(255, 255, 255, 0.1)' }
			},
			
		}
	};

	// On load
	useEffect(() => {

		// Get users initial data
		getAllUsersInitialData();

	}, []);

	// Get users initial data
	const getAllUsersInitialData = async () => {

		// Get from backend
		await getInitialData(function (response) {

			// Set site data
			setSiteData({
				labels: ['MEX', 'ARG', 'ROLA'],
				datasets: [
					{
						label: 'Users / Website',
						data: Object.values(response.data.usersWebsite),
						backgroundColor: [
							'#7E6000',
							'#FEBD00',
							'#BF8F01',
						],
						borderColor: [
							'#7E6000',
							'#FEBD00',
							'#BF8F01',
						],
						borderWidth: 1,
					},
				],
			});

			// Set gender data
			setGenderData({
				labels: ['Male', 'Female', 'Prefer not to answer', 'Other'],
				datasets: [
					{
						label: 'Users / Gender',
						data: Object.values(response.data.usersGender),
						backgroundColor: [
							'#7E6000',
							'#FEBD00',
							'#BF8F01',
							'#FED865',
						],
						borderColor: [
							'#7E6000',
							'#FEBD00',
							'#BF8F01',
							'#FED865',
						],
						borderWidth: 1,
					},
				],
			});

			// Order age data
			const ageOrdered = Object.keys(response.data.usersAge).sort().reduce(
				(obj, key) => {
					obj[key] = response.data.usersAge[key];
					return obj;
				},
				{}
			);

			// Set age data
			setAgeData({
				labels: Object.keys(ageOrdered),
				datasets: [
					{
						label: 'Users / Age',
						data: Object.values(ageOrdered),
						borderColor: '#FEBD00',
						backgroundColor: '#FEBD00',
					},
				],
			});

			// Set country data
			setCountryData({
				labels: Object.keys(response.data.usersCountry),
				datasets: [
					{
						label: 'Users / Country',
						data: Object.values(response.data.usersCountry),
						borderColor: '#FEBD00',
						backgroundColor: '#FEBD00',
					},
				],
			});

			// Remove loader
			toggleLoading(false);

		}, function (error) {

			// Show error
			console.log(error);

		});

	}

	return (
		<div
			id="homePage"
			style={{ paddingTop: 50, paddingBottom: 50 }}
		>
			{/* Container */}
			<div className="maxWidthCtr">
				{/* Preloader */}
				{isLoading ? <img className="sectionPreloader" src={Preloader1} alt="Loading" /> : null}
				{/* Grid */}
				<div className="gridContainer">
					{/* Site */}
					<div>
						{!isLoading ? <Doughnut data={siteData} options={siteOptions} height={250} /> : null}
					</div>
					{/* Gender */}
					<div>
						{!isLoading ? <Pie data={genderData} options={genderOptions} height={250} /> : null}
					</div>
					{/* Country */}
					<div>
						{!isLoading ? <Bar data={countryData} options={countryOptions} height={400} /> : null}
					</div>
					{/* Age */}
					<div>
						{!isLoading ? <Bar data={ageData} options={ageOptions} height={400} /> : null}
					</div>
				</div>
			</div>
		</div>
	);

}

export default HomePage;
