import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ children }) => {

	return (
		<div id="mainContainer">
			{/* Header */}
			<header>
				<img src="https://my.universalpictures-latam.com/static/img/logo.png" alt="Universal Pictures" />
			</header>
			{/* Current Route */}
			{children}
		</div>
	);

}

Header.propTypes = {
	children: PropTypes.object,
};

export default Header;
